@import '../fonts/FuturaLTPro-Book/styles.css';
@import '../fonts/FuturaLTPro-Light/styles.css';
@import '../fonts/FuturaLTPro-Medium/styles.css';
html,
body {
  padding: 0;
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: overlay;
}
.body-unscrollable {
  overflow: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(55, 55, 55, 0.8);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
a {
  text-decoration: underline;
}
.verif-code {
  display: flex;
  justify-content: center;
}
.verif-code > div {
  width: 100%;
}

.verif-code.account > div {
  max-width: 100%;
}

.verif-code > div > input {
  border: none !important;
  border-radius: 0 !important;
  color: rgb(0, 0, 0) !important;
  background-color: #f5f5f5 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 2px !important;
  text-align: center !important;
  font-family: 'FuturaLTPro-Medium', sans-serif !important;
  height: 64px !important;
  width: calc((100% - 5px) / 6) !important;
}

.verif-code.account > div > input {
  color: #000 !important;
  background-color: #f5f5f5 !important;
}
.styles_react-code-input-container__tpiKG {
  width: 100% !important;
}

.verif-code > div > input {
  margin: 0.5px;
}
.verif-code > div > input:first-child {
  margin: 0.5px 0.5px 0.5px 0px !important;
}
.verif-code > div > input:last-child {
  margin: 0.5px 0px 0.5px 0.5px !important;
}
.verif-code > div > input:focus {
  outline: none;
  border: 1px solid rgb(211, 147, 224) !important;
  caret-color: #000 !important;
}
.verif-code > div > input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
  -webkit-text-fill-color: #000 !important;
  caret-color: #000;
}
.verif-code.account > div > input:focus {
  caret-color: #000 !important;
}
.onfido-sdk-ui-Modal-inner,
.onfido-sdk-ui-Theme-step {
  border-radius: 0 !important;
  border: none !important;
  outline: none !important;
}

.onfido-sdk-ui-Modal-closeButtonLabel {
  word-break: initial !important;
}

.onfido-sdk-ui-PageTitle-titleSpan {
  color: #fff !important;
  font-size: 20px !important;
  font-family: 'FuturaLTPro-Medium', 'Tahoma', sans-serif !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  letter-spacing: 3px !important;
}

.onfido-sdk-ui-QRCode-qrCodeHelpButton:hover {
  background: none !important;
  color: #9f9f9f !important;
}

.onfido-sdk-ui-PageTitle-subTitle {
  color: #000 !important;
  font-weight: 400 !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-copyToClipboard,
.ods-button.-action--primary,
.ods-button.-action--secondary {
  text-transform: uppercase !important;
  font-family: 'FuturaLTPro-Medium', 'Tahoma', sans-serif !important;
  font-size: 11.375px !important;
  font-weight: 500 !important;
  line-height: 1.23 !important;
  letter-spacing: 2px !important;
  border-radius: 0 !important;
}

.onfido-sdk-ui-Theme-header {
  background-color: #5d6b78 !important;
}

.onfido-sdk-ui-Theme-helpList {
  color: white !important;
}

.onfido-sdk-ui-crossDevice-Intro-stage::before {
  background-color: #1e1e24 !important;
}

.onfido-sdk-ui-CameraPermissions-Primer-graphic {
  filter: grayscale(1) !important;
}

.onfido-sdk-ui-CameraPermissions-Primer-instructions {
  color: #9f9f9f !important;
}

.onfido-sdk-ui-Uploader-instructionsCopy {
  color: white !important;
}

.onfido-sdk-ui-Modal-closeButtonLabel {
  text-transform: uppercase !important;
}

.onfido-sdk-ui-NavigationBar-label {
  text-transform: uppercase !important;
}

.onfido-sdk-ui-Confirm-message {
  font-size: 14px !important;
  max-width: 60% !important;
  margin: 0 auto !important;
  margin-bottom: 24px !important;
}

.ods-button.-action--primary {
  background: #fff !important;
  color: #000 !important;
  border-color: unset !important;
}

.ods-button.-action--secondary {
  color: #fff !important;
  border-color: unset !important;
  background-color: unset !important;
}

.ods-button.-action--primary:hover,
.ods-button.-action--primary:focus {
  background-color: #d393e3 !important;
  color: #000 !important;
  box-shadow: unset !important;
  border-color: unset !important;
}

.ods-button.-action--secondary:hover,
.ods-button.-action--secondary:focus {
  background: transparent !important;
  color: #fff !important;
  border-color: unset !important;
  box-shadow: unset !important;
}

.onfido-sdk-ui-Theme-link {
  text-transform: capitalize;
  color: #fff !important;
  border: none;
}

.onfido-sdk-ui-Theme-link:hover,
.onfido-sdk-ui-Theme-link:focus {
  background: none !important;
  color: #9f9f9f !important;
}

.onfido-sdk-ui-Theme-icon,
.onfido-sdk-ui-Modal-closeButton,
.onfido-sdk-ui-NavigationBar-back,
.onfido-sdk-ui-crossDevice-Intro-stageIcon,
.onfido-sdk-ui-Photo-introIcon {
  filter: invert(100%) grayscale(100%) !important;
}

.onfido-sdk-ui-Modal-closeButtonFullScreen,
.onfido-sdk-ui-Theme-fullScreenStep .onfido-sdk-ui-NavigationBar-back {
  filter: none !important;
}

.onfido-sdk-ui-Modal-inner {
  background: #191919 !important;
}
.onfido-sdk-ui-Uploader-documentExampleLabel {
  color: #fff !important;
  opacity: 0.7 !important;
}
.onfido-sdk-ui-crossDevice-Intro-stageMessage-sms,
.onfido-sdk-ui-crossDevice-Intro-stageMessage-take-photos,
.onfido-sdk-ui-crossDevice-Intro-stageMessage-return-to-computer,
.onfido-sdk-ui-PageTitle-subTitle,
.onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel,
.onfido-sdk-ui-crossDevice-CrossDeviceLink-linkText,
.onfido-sdk-ui-QRCode-qrCodeHelpList {
  color: #fff !important;
}

.onfido-sdk-ui-Confirm-message,
.onfido-sdk-ui-crossDevice-CrossDeviceLink-label,
.onfido-sdk-ui-Photo-introBullets {
  color: #9f9f9f !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-copyToClipboard {
  border: none !important;
  color: #fff !important;
  font-size: 0.775em !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel::before,
.onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel::after {
  opacity: 0.1 !important;
}

.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer input {
  background: none !important;
  outline: none !important;
  color: #fff !important;
}

.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer {
  padding-left: 0 !important;
  border: none !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer {
  background: none !important;
  border: none !important;
  border-radius: 0 !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-numberInputSection {
  border: none !important;
  border-bottom: 2px solid #fff !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-numberInputSection button {
  background: none !important;
  color: #fff !important;
  width: auto;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer {
  box-shadow: none !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-numberInputSection button:hover,
.onfido-sdk-ui-crossDevice-CrossDeviceLink-numberInputSection button:focus,
.onfido-sdk-ui-crossDevice-CrossDeviceLink-copyToClipboard:hover,
.onfido-sdk-ui-crossDevice-CrossDeviceLink-copyToClipboard:focus {
  background: transparent !important;
  color: #fff !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceSubmit-listText {
  color: white !important;
}

.onfido-sdk-ui-Theme-textButton {
  color: white !important;
}

@media screen and (max-width: 768px) {
  .onfido-sdk-ui-PageTitle-titleSpan {
    margin-top: 50px !important;
  }
}

.onfido-sdk-ui-Theme-modalOverlay {
  z-index: 9 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}

/* break point */
@media only screen and (min-width: 540px) {
  .verif-code > div > input {
    font-size: 24px !important;
  }
}
@media only screen and (min-width: 768px) {
  .verif-code > div > input {
    height: 80px !important;
    padding: 0;
  }
  .verif-code.account > div > input {
    height: 64px !important;
    padding: 0;
  }
}
